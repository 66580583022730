import React from "react";
import { StyleSheet, TextInput, View } from "react-native";

const SearchBar = ({clicked, searchPhrase, setSearchPhrase, setClicked}) => 
{
    return (
        <View style={styles.container}>
            <View
                style=
                {
                    clicked
                        ? styles.searchBar__clicked
                        : styles.searchBar__unclicked
                }
            >
                <TextInput
                    style={styles.input}
                    placeholder="Search"
                    value={searchPhrase}
                    onChangeText={setSearchPhrase}
                    onClick={() => 
                    {
                        setClicked(true);
                    }}
                />
            </View>
        </View>
    );
};
export default SearchBar;

const styles = StyleSheet.create(
{
    container: 
    {
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        width: "90%",
    },
    searchBar__unclicked: 
    {
        padding: 10,
        flexDirection: "row",
        width: "100%",
        backgroundColor: "#d9dbda",
        borderRadius: 15,
        alignItems: "center",
    },
    searchBar__clicked: 
    {
        padding: 10,
        flexDirection: "row",
        width: "100%",
        backgroundColor: "#d9dbda",
        borderRadius: 15,
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    input: 
    {
        fontSize: 20,
        marginLeft: 10,
        width: "100%",
    },
});