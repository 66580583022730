import React, {useState, useEffect} from 'react';
import
{
    View,
    FlatList,
    StyleSheet,
    TextInput,
    Text,
    TouchableWithoutFeedback
} from 'react-native-web';
import SpoilerButton from '../components/SpoilerButton';
import StatisticsTable from '../components/StatisticsTable';
import AwardsTable from '../components/AwardsTable';
import YearsTable from '../components/YearsTable';
import TeamTable from '../components/TeamTable';
import SearchBar from '../components/SearchBar';
import List from '../components/List';
import { ScrollView } from "react-native-web";
import GameOverModal from '../components/GameOverModal';
import Cookies from 'js-cookie';

const BASE_URL = "https://football-game-backend.onrender.com";

function GameScreen() 
{
    const [player, setPlayer] = useState(null);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [clicked, setClicked] = useState(false);
    const [answer, setAnswer] = useState("");
    const [gameOver, setGameOver] = useState(false);
    const [score, setScore] = useState(100);
    const [playerList, setPlayerList] = useState([]);
    const [wrongAnswers, setWrongAnswers] = useState([]);


    const resetGame = () => 
    {
        setGameOver(false);
        setScore(100);
        setAnswer("");
        setWrongAnswers([]);
        setSearchPhrase("");
        setClicked(false);
        
        fetch(`${BASE_URL}/api/getRandomPlayer`,
        {
            method: "GET",
        })
        .then(response => response.json())
        .then(data => setPlayer(data))
        .catch(error => console.error("Error:", error));
    };

    useEffect(() => 
    {
        fetch(`${BASE_URL}/api/getRandomPlayer`,
        {
            method: "GET",
        })
        .then(response => response.json())
        .then(data => setPlayer(data))
        .catch(error => console.error("Error:", error));
    }, []);

    useEffect(() => 
    {
        fetch(`${BASE_URL}/api/getPlayerList`,
        {
            method: "GET",
        })
        .then(response => response.json())
        .then(data => setPlayerList(data))
        .catch(error => console.error("Error:", error));
    }, []);

    useEffect(() => 
    {
        if(answer)
        {
            if(answer === player.name)
            {
                setGameOver(true)
            }
            else
            {
                setWrongAnswers((previousWrongAnswers) => [...previousWrongAnswers, answer])
                setScore(score - 5)
            }
        }
        else
        {
            /* TODO: Add error state */
        }
    }, [answer])

    useEffect(() => 
    {
        if(score <= 0)
        {
            setGameOver(true)
        }
    }, [score])

    useEffect(() => 
    {
        const gamesPlayed = parseInt(Cookies.get('gamesPlayed') || '0', 10);
        const gamesWon = parseInt(Cookies.get('gamesWon') || '0', 10);
        const totalScore = parseInt(Cookies.get('totalScore') || '0', 10);

        Cookies.set('gamesPlayed', gamesPlayed + 1);
        if (score > 0) {
            Cookies.set('gamesWon', gamesWon + 1);
        }
        Cookies.set('totalScore', totalScore + score);

        console.log('games played:', gamesPlayed);
        console.log('games won:', gamesWon);
        console.log('average score:', (totalScore / gamesPlayed).toFixed(2));
    }, [gameOver])
    

    return (         
        <View style={styles.webpage}>
            <GameOverModal 
                isVisible={gameOver} 
                answer={player ? player.name : '?'}
                resetFunction={resetGame}
                stats={{
                    gamesPlayed: Cookies.get('gamesPlayed') || 0,
                    gamesWon: Cookies.get('gamesWon') || 0,
                    averageScore: (Cookies.get('totalScore') / Cookies.get('gamesPlayed')).toFixed(2) || 0,
                }}
            />
            {!gameOver && (
            <TouchableWithoutFeedback
            onPress={() => {
                if(clicked) setClicked(false);
            }}
            >
            <View style={{flex: 1, flexDirection:'row'}}>
                <View style={{flex: 2}}></View>
                <View style={styles.gameArea}>
                    <View style={styles.banner}>
                        <View style={styles.leftBanner}></View>
                        <View style={styles.middleBanner}>
                            <Text style={styles.titleText}>Sewell</Text>
                        </View>
                        <View style={styles.rightBanner}>
                            <Text style={styles.scoreText}>Score: {score}</Text>
                        </View>
                    </View>
                    <View style={styles.belowBanner}>
                        <View style={styles.gameBox}>
                            <View style={clicked ? styles.searchBar : styles.searchBar_unclicked}>
                                <SearchBar
                                    searchPhrase={searchPhrase}
                                    setSearchPhrase={setSearchPhrase}
                                    clicked={clicked}
                                    setClicked={setClicked}
                                />
                                <List
                                    searchPhrase={searchPhrase}
                                    data={playerList}
                                    clicked={clicked}
                                    setClicked={setClicked}
                                    setAnswer={setAnswer}
                                />
                            </View>
                            <View style={styles.topDataRow}>
                                <View style={{flex: 1}}>
                                    <SpoilerButton 
                                        buttonText="Position (-10)" 
                                        score={score}
                                        setScore={setScore}
                                        scoreSubtraction={10}
                                    >
                                        <Text style={styles.text}>{player ? player.position : "loading..."}</Text>
                                    </SpoilerButton>
                                </View>
                                <View style={{flex: 1}}>
                                    <SpoilerButton 
                                        buttonText="College (-10)"
                                        score={score}
                                        setScore={setScore}
                                        scoreSubtraction={10}
                                    >
                                        <Text style={styles.text}>{player ? player.college : "loading..."}</Text>
                                    </SpoilerButton>
                                </View>
                            </View>
                            <View style={styles.buttonField}>
                                <ScrollView>
                                <View style={styles.middleDataRow}>
                                    <View style={styles.seasonColumn}>
                                        <SpoilerButton 
                                            buttonText="Seasons (-15)"
                                            score={score}
                                            setScore={setScore}
                                            scoreSubtraction={15}
                                        >
                                            {player && player.season ? (
                                                <YearsTable seasons={player.season} />
                                            ) : (
                                                <Text style={styles.text}>Loading...</Text>
                                            )}
                                        </SpoilerButton>
                                    </View>
                                    <View style={styles.teamColumn}>
                                        <SpoilerButton 
                                            buttonText="Team (-15)"
                                            score={score}
                                            setScore={setScore}
                                            scoreSubtraction={15}
                                        >
                                            {player && player.season ? (
                                                <TeamTable seasons={player.season} />
                                            ) : (
                                                <Text style={styles.text}>Loading...</Text>
                                            )}
                                        </SpoilerButton>
                                    </View>
                                    <View style={styles.statsColumn}>
                                        <SpoilerButton 
                                            buttonText="Statistics (-20)"
                                            score={score}
                                            setScore={setScore}
                                            scoreSubtraction={20}
                                        >
                                            {player && player.season ? (
                                                <StatisticsTable seasons={player.season} />
                                            ) : (
                                                <Text style={styles.text}>Loading...</Text>
                                            )}
                                        </SpoilerButton>
                                    </View>
                                    <View style={styles.awardsColumn}>
                                        <SpoilerButton 
                                            buttonText="Awards (-20)"
                                            score={score}
                                            setScore={setScore}
                                            scoreSubtraction={20}
                                        >
                                            {player && player.season ? (
                                                <AwardsTable seasons={player.season} />
                                            ) : (
                                                <Text style={styles.text}>Loading...</Text>
                                            )}
                                        </SpoilerButton>
                                    </View>
                                </View>
                                </ScrollView>
                            </View>
                        </View>
                        <View style={styles.belowGame}>
                            <Text style={styles.scoreText}>Incorrect Answers: {wrongAnswers.join(', ')}</Text>
                        </View>
                    </View>
                </View>
                <View style={{flex: 2}}></View>
            </View>    
            </TouchableWithoutFeedback>
            )}
        </View>
    );
}

const styles = StyleSheet.create(
    {
        webpage:
        {
            flexDirection: 'row',
            padding: 10,
            backgroundColor: '#34334f',
            width: '100%',
            height: '100vh'
        },
        gameArea:
        {
            flexDirection: 'column',
            padding: 20,
            flex: 15,
        },
        banner:
        {
            flex: 1,
            flexDirection: 'row',
            padding: 4,
        },
        leftBanner:
        {
            flex: 1,
        },
        middleBanner:
        {
            flex: 3,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        titleText:
        {
            fontSize: 40,
            fontWeight: 'bold',
            color: 'white'
        },
        rightBanner:
        {
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        scoreText:
        {
            marginBottom: 27,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 20,
        },
        belowBanner:
        {
            flex: 7,
            flexDirection: 'column',
        },
        gameBox:
        {
            flex: 3,
            flexDirection: 'column'
        },
        searchBar:
        {
            flex: 6,
            alignItems: 'center',
            justifyContent: 'top',
            flexDirection: 'column',
        },
        searchBar_unclicked:
        {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'top',
            flexDirection: 'column',
        },
        buttonField:
        {
            flex: 7,
            flexDirection: 'column'
        },
        topDataRow:
        {
            flex: 1,
            flexDirection: 'row',
            padding: 5
        },
        text:
        {
            fontSize: 25,
            fontWeight: 'bold',
            color: 'white'
        },
        middleDataRow:
        {
            flex: 5,
            flexDirection: 'row',
        },
        seasonColumn:
        {
            flex: 2,
        },
        teamColumn:
        {
            flex: 2,
        },
        statsColumn: 
        {
            flex: 10,
            justifyContent: 'center'
        },
        awardsColumn:
        {
            flex: 3,
        },
        belowGame:
        {
            flex: 1, 
            flexDirection: 'row',
            alignItems: 'flex-end'
        }
    }
)
export default GameScreen;