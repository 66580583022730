import { hover } from "@testing-library/user-event/dist/hover";
import React, {useState} from "react";
import 
{
    StyleSheet,
    Text,
    View,
    FlatList,
    SafeAreaView,
    Pressable
} from "react-native";
import { ScrollView } from "react-native-web";

const Item = ({ name, setAnswer }) => 
{
    const [hovered, setHovered] = useState(false);
    return(
        <Pressable
            onHoverIn={() => setHovered(true)}
            onHoverOut={() => setHovered(false)}
            onPress={() => setAnswer(name)}
        >
            <View style={[styles.item, {borderWidth: hovered ? 1 : 0}]}>
                <Text style={styles.title}>{name}</Text>
            </View>
        </Pressable>
    );
}

const List = ({ searchPhrase, clicked, setClicked, data, setAnswer }) => 
{
    const renderItem = ({ item }) => 
    {
        if(searchPhrase === "") 
        {
            return <Item name={item} setAnswer={setAnswer}/>;
        }
        if(item.toUpperCase().includes(searchPhrase.toUpperCase().trim().replace(/\s/g, ""))) 
        {
            return <Item name={item} setAnswer={setAnswer}/>;
        }
    };

    if(!clicked)
        return null;

    return (
        <ScrollView style={styles.container} keyboardShouldPersistTaps="handled"
            onStartShouldSetResponder={() => 
            {
                setClicked(false);
            }}
        >
            {clicked && (
            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                style={{ backgroundColor: '#d9dbda' }}
            />
            )}
        </ScrollView>
    );
};

export default List;

const styles = StyleSheet.create(
{
    container: 
    {
        height: "85%",
        width: "75%",
    },
    item: 
    {
        height: 45,
        justifyContent: 'center'
    },
    title: 
    {
        fontSize: 15,
        fontWeight: "bold",
        marginBottom: 3,
        marginLeft: 5
    },
});