  import React, { useState } from 'react';
  import { View, Text, Pressable, StyleSheet } from 'react-native';

  const SpoilerButton = ({ buttonText, children, score, setScore, scoreSubtraction }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
      <View style={styles.container}>
        {!isVisible && (
        <Pressable 
          style={({ pressed }) => [
            styles.buttonContainer,
            isVisible && styles.transparentButton,
            pressed && styles.pressedButton,
          ]}
          onPress={() => 
            {
              setIsVisible(!isVisible)
              setScore(score - scoreSubtraction)
            }}
        >
            <Text style={styles.buttonText}>{buttonText}</Text>
        </Pressable>
        )}
        
        {isVisible && (
          <View style={styles.content}>
            {children}
          </View>
        )}
      </View>
    );
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginVertical: 10,
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'top',
    },
    buttonContainer: {
      backgroundColor: '#007bff', // Initial button color
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      height: 50,
      width: 75,
      borderRadius: '10px',
    },
    buttonText: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    transparentButton: {
      backgroundColor: 'transparent', // Transparent background when content is visible
    },
    pressedButton: {
      opacity: 0.5, // Slightly dimmed when pressing the button
    },
    content: {
      flex: 24,
      justifyContent: 'center',
      alignItems: 'left',
      textAlign: 'center'
    },
  });

  export default SpoilerButton;