import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const YearsTable = ({ seasons }) =>
{
  return(
    <View style={styles.container}>
      <View style={styles.headerRow}>
        <Text style={styles.headerText}>Seasons Played</Text>
      </View>
        {seasons.map((season, index) => (
            <View key={index} style={styles.row}>
                <Text style={styles.cell}>{season.year}</Text>
            </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#DDD',
    padding: 8,
    height: "60px"
  },
  headerText: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EEE',
    padding: 8,
    height: 80,
    alignItems: 'center'
  },
  cell: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold'
  },
});
export default YearsTable