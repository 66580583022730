import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const StatisticsTable = ({ seasons }) =>
{
  return(
    <View style={styles.container}>
      <View style={styles.headerRow}>
        <Text style={styles.headerText}>Games Played</Text>
        <Text style={styles.headerText}>Rushing Yards</Text>
        <Text style={styles.headerText}>Rushing TDs</Text>
        <Text style={styles.headerText}>Receiving Yards</Text>
        <Text style={styles.headerText}>Receiving TDs</Text>
        <Text style={styles.headerText}>Passing Yards</Text>
        <Text style={styles.headerText}>Passing TDs</Text>
        <Text style={styles.headerText}>Fumbles</Text>
      </View>
      {seasons.map((season, index) => (
        <View key={index} style={styles.row}>
          <Text style={styles.cell}>{season.games}</Text>
          <Text style={styles.cell}>{season.rushing.yds}</Text>
          <Text style={styles.cell}>{season.rushing.td}</Text>
          <Text style={styles.cell}>{season.receiving.yds}</Text>
          <Text style={styles.cell}>{season.receiving.td}</Text>
          <Text style={styles.cell}>{season.passing.yds}</Text>
          <Text style={styles.cell}>{season.passing.td}</Text>
          <Text style={styles.cell}>{season.fumbles}</Text>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#DDD',
    padding: 8,
    height: "60px"
  },
  headerText: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EEE',
    padding: 8,
    height: 80,
    alignItems: 'center'
  },
  cell: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold'
  },
});
export default StatisticsTable