import React, { useState, useEffect } from 'react';
import { Modal, View, Text, Button, StyleSheet} from 'react-native-web';

const GameOverModal = ({isVisible, answer, resetFunction, stats}) => 
{
    return(
        <Modal visible={isVisible} transparent={true} animationType='slide'>
            <View style={styles.container}>
                <View style={styles.modalContent}>
                    <View style={{flex: 1}}>
                        <Text style={styles.header}>Your Results</Text>
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={styles.solution}>Solution: {answer}</Text>
                    </View>
                    <View style={{flex: 4, flexDirection: 'column'}}>
                        <View style={{flex: 1, alignItems: 'center'}}>
                            <Text style={styles.section}>Scores</Text>
                        </View>
                        <View style={{flex: 5, flexDirection: 'row'}}>
                            <View style={styles.statContainer}>
                                <Text style={styles.text}>Games Played:</Text>
                                <Text style={styles.text}>{stats.gamesPlayed}</Text>
                            </View>
                            <View style={styles.statContainer}>
                                <Text style={styles.text}>Games</Text>
                                <Text style={styles.text}>Won:</Text>
                                <Text style={styles.text}>{stats.gamesWon}</Text>
                            </View>
                            <View style={styles.statContainer}>
                                <Text style={styles.text}>Average Score:</Text>
                                <Text style={styles.text}>{stats.averageScore}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flex: 2}}>
                        <Button 
                            title="Play Again"
                            onPress={resetFunction}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create(
{
    container: 
    {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent:
    {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: '40%',
        height: '70%',
        alignItems: 'center',
        flexDirection: 'column'
    },
    header: 
    {
        fontSize: 35,
        fontWeight: 'bold',
        marginBottom: 10,
        color: 'grey'
    },
    solution: 
    {
        fontSize: 25,
        fontWeight: 'bold',
        marginBottom: 10,
        color: 'black'
    },
    section: 
    {
        fontSize: 25,
        color: 'grey'
    },
    statContainer:
    {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        
    },
    text: 
    {
        fontSize: 16,
        textAlign: 'center'
    },
});
export default GameOverModal;