import React from 'react';
import { View, Text, StyleSheet, Image} from 'react-native';

const teamLogos = {
    ARZ: require('../images/ARZlogo.png'),
    ATL: require('../images/ATLlogo.png'),
    BLT: require('../images/BLTlogo.png'),
    BUF: require('../images/BUFlogo.png'),
    CAR: require('../images/CARlogo.png'),
    CHI: require('../images/CHIlogo.png'),
    CIN: require('../images/CINlogo.png'),
    CLV: require('../images/CLVlogo.png'),
    DAL: require('../images/DALlogo.png'),
    DEN: require('../images/DENlogo.png'),
    DET: require('../images/DETlogo.png'),
    GB: require('../images/GBlogo.png'),
    HST: require('../images/HSTlogo.png'),
    IND: require('../images/INDlogo.png'),
    JAX: require('../images/JAXlogo.png'),
    KC: require('../images/KClogo.png'),
    LV: require('../images/LVlogo.png'),
    LAC: require('../images/LAClogo.png'),
    LA: require('../images/LAlogo.png'),
    MIA: require('../images/MIAlogo.png'),
    MIN: require('../images/MINlogo.png'),
    NE: require('../images/NElogo.png'),
    NO: require('../images/NOlogo.png'),
    NYG: require('../images/NYGlogo.png'),
    NYJ: require('../images/NYJlogo.png'),
    PHI: require('../images/PHIlogo.png'),
    PIT: require('../images/PITlogo.png'),
    SF: require('../images/SFlogo.png'),
    SEA: require('../images/SEAlogo.png'),
    TB: require('../images/TBlogo.png'),
    TEN: require('../images/TENlogo.png'),
    WAS: require('../images/WASlogo.png'),
  };

const TeamTable = ({ seasons }) =>
{
  return(
    <View style={styles.container}>
      <View style={styles.headerRow}>
        <Text style={styles.headerText}>Team</Text>
      </View>
        {seasons.map((season, index) => (
            <View key={index} style={styles.row}>
                <Image
                    source={teamLogos[season.team]}
                    style={styles.tinyLogo}
                    alt={season.team}
                />
                <Text style={styles.cell}>{season.team}</Text>
            </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#DDD',
    padding: 8,
    height: "60px"
  },
  headerText: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EEE',
    padding: 8,
    height: 80,
    alignItems: 'center'
  },
  tinyLogo: {
    width: 80,
    height: 70,
    marginRight: 10,
  },
  cell: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold'
  },
});
export default TeamTable