import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const AwardsTable = ({ seasons }) =>
{
  return(
    <View style={styles.container}>
      <View style={styles.headerRow}>
        <Text style={styles.headerText}>Awards Won</Text>
      </View>
      {seasons.map((season, index) => (
        <View key={index} style={styles.row}>
          <View style={styles.cell}>
            {season.awards.length > 0 ? (
              season.awards.map((award, i) => (
                <Text key={i} style={styles.awardText}>
                  {award.award}
                </Text>
              ))
            ) : (
              <Text style={styles.noAwardsText}>-</Text>
            )}
          </View>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    width: "200px"
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#DDD',
    padding: 8,
    height: "60px"
  },
  headerText: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EEE',
    padding: 8,
    height: 80,
    alignItems: 'center'
  },
  cell: {
    flex: 1,
    flexDirection: 'row'
  },
  awardText: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold'
  },
  noAwardsText: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold'
  },
});
export default AwardsTable